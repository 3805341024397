import gql from 'graphql-tag'
import { Product as ProductFragment, BaseProduct } from '@/graphql/fragments'

export const Products = gql`
  query products {
    products {
      ...${ BaseProduct }
    }
  } 
`

export const RecommendedProducts = gql`
  query recommendedProducts($id: ID) {
    recommendedProducts(id: $id) {
      ...${ BaseProduct }
    }
  } 
`

export const Featured = gql`
  query featuredProducts($storeId: ID!) {
    featuredProducts(storeId: $storeId) {
      ...${ BaseProduct }
    }
  } 
`

export const SearchProducts = gql`
  query searchProducts($search: String!, $limit: ID, $storeId: ID) {
    searchProducts(search: $search, limit: $limit, storeId: $storeId) {
      ...${ BaseProduct }
    }
  } 
`

export const SimpleProperties = gql`
  query productSimpleProperties($id: ID!) {
    productSimpleProperties(id: $id) {
      id
      name
      value
    }
  } 
`

export const Product = gql`
  query product($id: ID!) {
    product(id: $id) {
      ...${ ProductFragment }
    }
  } 
`

export const AdminProduct = gql`
  query admin_product($id: ID!) {
    admin_product(id: $id) {
      ...${ ProductFragment }
    }
  } 
`
